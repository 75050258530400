.overview-card {
    width: 100%;
    height: 130px !important;
    border: 'none';
    background: $secondary;
    box-shadow: none;
    border-radius: 16px;
    position: relative;
    display: flex;
    padding: 10px;
}

.card-title {
    font-size: small;
    font-weight: 400;
}

.card-subtitle {
    font-size: small;
    font-weight: 400;
}

.card-value {
    font-size: 40px;
    font-weight: 700;
}

.dashboard-card-icon {
    border-radius: 70%;
    position: 'absolute';
    left: 12px;
    padding: 1px;
    background-color: $white;
    svg path {
        fill: $primary;
    }
}

.legend-item {
    display: flex;
    align-items: center;
}

.dashboard-chart-tooltip {
    position: fixed;
    background-color: white;
    padding: 8px 12px;
    border-radius: 6px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    pointer-events: none;
    opacity: 0;
    font-size: 13px;
    z-index: 1000;
    transition: opacity 0.2s ease-in-out;
    white-space: nowrap;
}

.dashboard-donut-legend {
    width: 12px;
    height: 12px;
    margin-right: 6px;
    border-radius: 50%;
}

.dashboard-donut-legend-box {
    display: flex;
    align-items: center;
    margin: 0 8px;
    font-size: 12px;
}

.dashboard-donut-chart-view-btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.border-radius-0 {
    border-radius: 0;
}

.last-updated-date-container{
    width: '100%';
    height: '100%';
    display: flex;
    justify-content: end;
    background: $tos-bg;
    border-top: 1px $border solid;
    border-bottom: 1px $border solid;
}

.last-updated-date-text {
    width: '100%';
    height: '100%';
    justify-content: 'center';
    display: 'flex';
    flex-direction: 'column';
    color: '#212529';
    font-size: 14;
    line-height: 3;
    word-wrap: 'break-word';
    padding-right: 15px;
}