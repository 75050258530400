.file-upload-box {
    border: 1px dashed $border;
    padding: 40px;
    width: 85%;
    height: 25%;
    border-radius: 36px;
    background-color: $bg;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    .file-input-text {
        width: '100%';
        height: '100%';
        font-size: 20px;
        text-align: 'center';
        font-weight: 700;
        word-wrap: 'break-word';
        justify-content: center;
    }
    .file-input-subtext {
        text-align: 'center';
    }

    input[type='file'] {
        display: none; // Hides the default file input
    }
}

.steps-badge {
    padding-right: 6%;
    display: flex;
    justify-content: end;
    padding-bottom: 3%;
}

.contract-file-item {
    flex-grow: 1;
}

.contract-file-listing-title {
    border-bottom: 1px solid $border;
    padding: 10px 5px;
    font-weight: 700;
}

.contract-file-list-item {
    border: none !important;
    border-bottom: 1px solid #dee2e6 !important; /* Only bottom border */
    padding: 12px 5px;
}

.file-svg-icon {
    width: 42px;
    height: 47.36px;
    top: 189.11px;
    left: 867px;
    opacity: 1px;
    color: $primary;
}
