/* FileBrowser CSS */
.file-browser {
    color: $primary;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.file-listing {
    flex: 1;
}

.file-controls {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
}

.create-folder-button {
    background-color: #007bff;
    color: #fff;
}

.file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    border-bottom: 1px solid #e0e0e0;
    transition: background-color 0.2s;
}

.file-item:hover {
    background-color: #f9f9f9;
}

/* Styling for folder and file containers */
.file-item .d-flex {
    display: flex;
    align-items: center;
    gap: 8px;
}

/* File icon styling */
.file-icon,
.folder-icon {
    font-size: 1.5rem;
    margin-right: 8px;
    fill: #d8bb27;
}

/* File and folder name styling */
.file-item button {
    background: none;
    border: none;
    padding: 0;
    font-size: 1rem;
    color: #007bff;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.file-item button:hover {
    text-decoration: underline;
}

/* Styling for file actions */
.file-actions {
    display: flex;
    align-items: center;
    gap: 8px;
}

/* Action dropdown alignment */
.file-actions .dropdown-menu {
    right: 0;
    left: auto;
    min-width: 200px;
}

/* Ensure icons and file names are aligned properly */
.file-content {
    display: flex;
    align-items: center;
    flex: 1;
    gap: 8px;
}

/* Ensure action dropdown aligns to the right */
.file-item .file-actions {
    margin-left: auto;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .file-item {
        flex-wrap: wrap;
    }

    .file-actions {
        margin-left: 0;
        margin-top: 8px;
        justify-content: flex-end;
        width: 100%;
    }
}
