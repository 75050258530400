.breadcrumb-item {
    display: flex;
    align-items: center;
}

.breadcrumb-item + .breadcrumb-item::before {
    content: '›';
    color: #6c757d;
    padding: 0 8px;
    font-weight: bold;
    font-size: large;
}

.upload-box {
    border: 1px dashed $border;
    padding: 40px;
    width: 558px;
    height: 287px;
    border-radius: 36px;
    background-color: $bg;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    .file-input-text {
        width: '100%';
        height: '100%';
        text-align: 'center';
        font-weight: 700;
        word-wrap: 'break-word';
        justify-content: center;
    }
    .file-input-subtext {
        text-align: 'center';
    }

    input[type='file'] {
        display: none; // Hides the default file input
    }
}

.file-svg {
    width: 42px;
    height: 47.36px;
    top: 189.11px;
    left: 867px;
    opacity: 0px;
    color: $primary;
}

.custom-file-upload {
    background-color: $bg;
    color: $text-dark;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    display: inline-block;
}

.file-listing {
    width: 100%;
    padding: 25px 15px;
    .file-listing-title {
        border-bottom: 1px solid $border;
        padding: 10px 5px;
        font-weight: 700;
    }
}

.file-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
        margin: 5px 0;
        padding: 10px;
        background-color: #f9f9f9;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;

        .file-item {
            flex-grow: 1;
        }

        .drag-handle {
            width: 10px;
            height: 10px;
            background-color: #007bff;
            border-radius: 50%;
            cursor: move;
        }
    }
}

.dragging {
    opacity: 0.5;
}

.section-item {
    border-bottom: 1px solid $border;
    padding: 10px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.file-list-icon {
    fill: $primary;
    color: $primary;
    width: toRem(50);
    height: toRem(50);
}

.section-list-title {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    border-bottom: 1px solid $border;
}

.breadcrumb-section {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    border-bottom: 1px solid $border;
    padding: 5px 20px;

    .breadcrumb-item a {
        text-decoration: none;
    }
}

.breadcrumb-icon {
    margin-right: 2px;
    display: flex;
    align-items: center;
    width: 20px;
    height: 26px;
}

.breadcrumb-item-text {
    font-size: larger;
    font-weight: 600;
    color: $dark;
    text-decoration: none;
}

.button-title {
    background-color: $primary;
}

.file-list-item {
    border: none !important;
    border-bottom: 1px solid #dee2e6 !important; /* Only bottom border */
    padding-bottom: 10px;
}

.version-history {
    margin-top: 10%;
}
