.background_branding_container {
    .login-page {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100vh;
        background: linear-gradient(180deg, #00527c 0%, #03263b 100%);

        .left-section {
            height: 100%;
            text-align: left;
            width: 500px;
            padding: 100px 36px 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: $white;

            .left-section-h1 {
                width: 80%;
                font-size: 52px;
                line-height: 54px;
                font-weight: 300;
                padding-bottom: 20px;
                margin: 0;
            }

            .left-section-p {
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                color: #ffffff;
                padding-bottom: 50px;
            }

            .logo {
                position: fixed;
                top: 0;
                left: 36px;
            }

            .learn-more-button {
                padding: 6px 12px;
                border-radius: 6px;
                height: 38px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                color: #212529;
                background-color: #ffffff;
                border: 1px solid #cbd5e1;
                width: auto !important;
                text-decoration: none;

                &:hover {
                    background-color: #e6e6e6;
                }
            }

            .arrow-icon {
                z-index: 1;
            }

            .panel-right {
                margin-left: auto;
                height: 100%;
                width: 36%;
                max-width: 36%;
                display: flex;
                /* align-items: flex-end; */
                overflow: auto;
                padding-top: 40px;
            }

            .copy-right {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                padding: 0 0 0 36px;
                z-index: 3;
            }

            .copy-right p {
                font-size: 14px;
                line-height: 14px;
                color: #ffffff;
                font-weight: 400;
                text-align: left;
                padding: 0;
                margin: 0;
            }

            .cta-links {
                display: flex;
                width: 36%;
                background: #ffffff;
                justify-content: flex-end;
                padding: 10px 36px;
            }

            .cta-links a {
                color: #18468b;
                padding-left: 24px;
                font-size: 14px;
                display: inline-block;
            }

            .panel-left {
                height: 100%;
                text-align: left;
                width: 500px;
                padding: 100px 36px 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            .panel-left h1 {
                font-size: 52px;
                line-height: 54px;
                font-weight: 300;
                padding-bottom: 20px;
                margin: 0;
                color: #ffffff;
            }

            .panel-left p {
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                color: #ffffff;
                padding-bottom: 50px;
            }
        }

        .company-website {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            width: 50%;
            margin: 0 auto;
        }

        .company-website img {
            width: 100%;
        }

        .right-section {
            margin-left: auto;
            height: 100%;
            width: 36%;
            max-width: 36%;
            display: flex;
            overflow: auto;
            padding-top: 40px;

            .login-container {
                width: 100%;
                margin: 0 auto;
                height: 100%;
                overflow-y: auto;
                overflow-x: hidden;
                padding: 40px 80px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                background-color: $white;
                border-right: 1px solid #cbd5e1;
                border-radius: 25px 0 0 0;
                margin-bottom: 0;
                overflow: auto;
                padding-bottom: 40px;
                z-index: 2;

                .login-logo {
                    display: block !important;
                    position: relative !important;
                    left: -8px !important;
                    margin-bottom: 28px !important;
                }

                h2 {
                    font-size: 1.2rem;
                    margin-bottom: 20px;
                    color: #003b62;
                }

                .login-form {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    margin-bottom: 20px;

                    input {
                        padding: 12px;
                        border: 1px solid #ccc;
                        border-radius: 4px;
                        font-size: 1rem;

                        &:focus {
                            border-color: #003b62;
                            outline: none;
                            box-shadow: 0 0 5px rgba(0, 59, 98, 0.5);
                        }
                    }

                    .forgot-password {
                        font-size: 0.9rem;
                        color: #003b62;
                        text-decoration: none;
                        margin-top: -10px;
                        align-self: flex-end;

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    .sign-in-button {
                        background-color: #003b62;
                        color: white;
                        padding: 12px;
                        font-size: 1rem;
                        font-weight: bold;
                        border: none;
                        border-radius: 4px;
                        cursor: pointer;
                        transition: background-color 0.3s;

                        &:hover {
                            background-color: #005491;
                        }
                    }
                }

                h3 {
                    font-size: 1rem;
                    color: #333;
                    margin: 20px 0;
                }

                .sso-button {
                    background-color: #005491;
                    color: white;
                    padding: 12px;
                    font-size: 1rem;
                    font-weight: bold;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                    width: 100%;
                    transition: background-color 0.3s;

                    &:hover {
                        background-color: #003b62;
                    }
                }

                .privacy-policy {
                    display: block;
                    margin-top: 20px;
                    font-size: 0.9rem;
                    color: #999;
                    text-decoration: none;

                    &:hover {
                        color: #333;
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        .login-page {
            flex-direction: column;

            .left-section {
                padding: 40px;
                text-align: center;

                .logo {
                    margin: 0 auto 20px auto;
                }

                h1 {
                    font-size: 2rem;
                }

                p {
                    font-size: 1rem;
                    margin-bottom: 30px;
                }

                .learn-more-button {
                    margin: 0 auto;
                    width: 100%;
                }
            }

            .right-section {
                padding: 20px;

                .login-container {
                    width: 100%;
                }
            }
        }
    }
}
